// variable

$header-height: 4rem;
$sidebar-width: 250px;

// *----------------------------------------------
// | Color
// -----------------------------------------------*/
$color-white: #fff;
$color-grey: #f6f7fb;
$color-light-grey: #eeeef9;
$color-dark: #51556d;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Helvetica;
}
.sidebar {
  margin-top: $header-height;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 82px;
  background-color: $color-white;
  padding: 14px 1rem 1rem;
  z-index: 99;
  transition: all 0.5s ease;
  border-top: 2px solid $color-grey;
}
.sidebar.open {
  width: $sidebar-width;
}
.sidebar i {
  color: $color-dark;
  height: $header-height;
  min-width: 50px;
  font-size: 2rem;
  text-align: center;
  line-height: $header-height;
}
.sidebar .nav-list {
  height: 100%;
}
.sidebar li {
  position: relative;
  margin-bottom: 0.5rem;
  list-style: none;
}

.sidebar li .tooltip {
  position: absolute;
  top: -20px;
  left: calc(100% + 15px);
  z-index: 3;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 400;
  opacity: 0;
  white-space: nowrap;
  pointer-events: none;
  transition: 0s;
}
.sidebar li:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  transition: all 0.5s ease;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  background-color: $color-dark;
}
.sidebar.open li .tooltip {
  display: none;
}
.sidebar.open input {
  padding: 0 20px 0 50px;
  width: 100%;
}
.sidebar li div {
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 0.5rem;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
}
.sidebar li a {
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 0.5rem;
  align-items: center;
  text-decoration: none;
}
.sidebar li a.active,
.sidebar li div.active {
  background-color: $color-dark;
  color: $color-white;
}

.sidebar li a.active i,
.sidebar li div.active i {
  color: $color-white;
}

.sidebar li a.active:visited,
.sidebar li a.active:link {
  color: $color-white;
}

.sidebar li a:visited,
.sidebar li a:link {
  color: $color-dark;
}

.sidebar li a:hover,
.sidebar li div:hover {
  background-color: $color-dark;
}
.sidebar li a .links_name,
.sidebar li div .links_name {
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: all ease 0.1s;
}
.sidebar.open li a .links_name,
.sidebar.open li div .links_name {
  opacity: 1;
  pointer-events: auto;
}
.sidebar li a:hover .links_name,
.sidebar li a:hover i,
.sidebar li div:hover .links_name,
.sidebar li div:hover i {
  color: white;
}
.sidebar li i {
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  border-radius: 12px;
}
.content-section {
  padding: 1rem;
  position: relative;
  background: $color-grey;
  min-height: calc(100vh - $header-height);
  top: 0;
  left: 82px;
  width: calc(100% - 82px);
  transition: all 0.5s ease;
  z-index: 2;
}
.sidebar.open ~ .content-section {
  left: $sidebar-width;
  width: calc(100% - $sidebar-width);
}

.sidebar {
  ul {
    padding-left: 0;
  }
}

// sidebar Group
.sidebar-group {
  border-radius: 5px;

  a {
    // nav item in group
    padding: 10px;
    padding-left: 20px;
  }
}
.sidebar-group.active {
  background-color: #f6f7fb;
}

// End Sidebar Group

// Header
.header {
  height: $header-height;
  display: flex;
  background-color: $color-white;

  .logo-section {
    display: flex;
    flex-direction: row;
    width: $sidebar-width;
    margin-left: 0;
    margin-right: 0;

    .menu-icon {
      width: 82px;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        font-size: 1.5rem;
        color: $color-dark;
      }
    }

    .desktop-logo {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: inherit;
      img {
        width: 40px;
        height: 40px;
      }
    }

    .mobile-logo {
      display: none;
    }

    .logo {
      height: auto;
      width: 100px;
    }
  }

  .header-content {
    display: flex;
    flex: 1 0 auto;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    border-left: 2px solid $color-grey;
    font-size: 1.25rem;
  }
}

.pointer {
  cursor: pointer;
}

// Set Responsive on mobile
@media (max-width: 480px) {
  .sidebar li .tooltip {
    display: none;
  }

  .sidebar {
    position: absolute;
    padding: 0px;
    width: 0px;
  }
  .sidebar.open {
    padding: 6px 14px;
    width: 100vw;
  }

  .content-section {
    left: 0px;
    width: 100%;
  }
  .sidebar.open ~ .content-section {
    left: 0px;
    width: 100%;
  }

  .sidebar.open .nav-list {
    opacity: 1;
  }

  .sidebar .nav-list {
    opacity: 0;
    transition: all 0.5s ease;
  }

  // Header
  .header {
    border-bottom: 2px solid #c8c9cd;
    .logo-section {
      width: 100%;
      .desktop-logo {
        display: none;
      }
      .mobile-logo {
        display: flex;
        align-items: center;
      }
    }
    .header-content {
      display: none;
    }
  }
}

// dropdown actions
.header .custom-dropdown .dropdown-menu {
  padding: 0.6rem 0;
}

.sidebar-group.active .divider {
  border-bottom: 2px solid #dee1ed;
}
